import { UAParser } from 'ua-parser-js';

export function getDeviceBasics() {
  const ret = {
    osName: '',
    isMobile: false,
  };

  const ua = UAParser();
  const osName = ((ua && ua.os && ua.os.name) || '').toLowerCase();
  const deviceType = ((ua && ua.device && ua.device.type) || '').toLowerCase();
  if (['ios', 'android'].indexOf(osName) !== -1) {
    ret.isMobile = true;
  } else if (deviceType === 'mobile') {
    ret.isMobile = true;
  }

  ret.osName = osName;

  return ret;
}

export default {};
