import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const pcRouteMap = {
  ai: 'msmart',
  technology: 'mlvzhou',
};
const mRouteMap = {
  msmart: 'ai',
  mlvzhou: 'technology',
};

const routes = [
  {
    path: '/',
    redirect: { path: '/lvzhou' },
  },
  {
    path: '/lvzhou',
    redirect: { name: 'ai' },
    children: [
      {
        path: 'ai',
        name: 'ai',
        component: () => import(/* webpackChunkName: "kedou/ai" */ '../views/kedou/AIView.vue'),
        meta: {
          index: 3,
        },
      },
      {
        path: 'technology',
        name: 'technology',
        component: () => import(/* webpackChunkName: "kedou/lvzhou" */ '../views/kedou/LvZhou.vue'),
        meta: {
          index: 4,
        },
      },
      {
        path: 'settle',
        name: 'settle',
        component: () =>
          import(/* webpackChunkName: "kedou/settle" */ '../views/kedou/SettleViewNew.vue'),
        meta: {
          index: 5,
        },
      },
      {
        path: 'settleOld',
        name: 'settleOld',
        component: () =>
          import(/* webpackChunkName: "kedou/settle" */ '../views/kedou/SettleView.vue'),
        meta: {
          index: 6,
        },
      },
    ],
  },
  {
    path: '/mobile',
    name: '蝌蚪',
    children: [
      {
        path: 'msmart',
        name: 'msmart',
        component: () =>
          import(/* webpackChunkName: "mobile/msmart" */ '../views/mobile/MobileView.vue'),
      },
      {
        path: 'mlvzhou',
        name: 'mlvzhou',
        component: () =>
          import(/* webpackChunkName: "mobile/mlvzhou" */ '../views/mobile/LvZhou.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (/^\/download/i.test(to.fullPath) || /^\/lvzhou\/settle/i.test(to.fullPath)) {
    next();
  } else if (store.state.isPc) {
    if (to?.name?.includes('m')) {
      router.replace({ name: mRouteMap[to.name] });
    } else {
      next();
    }
  } else if (!to?.name?.includes('m')) {
    router.replace({ name: pcRouteMap[to.name] });
  } else {
    next();
  }
});

export default router;
