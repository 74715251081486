<template>
  <section :class="`header ${theme} page-${$route.name}`">
    <div class="header_content">
      <div class="logo" @click="logoClick()" />
      <div class="nav_menu">
        <section class="nav_list">
          <button
            v-for="item in routerList"
            :key="item.name"
            :class="['nav_item', $route.name === item.name ? 'nav_active' : '']"
            @click="navClick(item)"
          >{{ item.label }}</button>
          <div class="nav_item">
            <span>下载</span>
            <div class="code_list">
              <div class="arrow"></div>
              <div v-if="false" class="code_item">
                <div class="code-container">
                  <DownloadQrCode type="user" size="100" logo="xiaoheipao"/>
                </div>
                <span>小黑袍</span>
              </div>
              <div v-if="false" class="code_item">
                <div class="code-container">
                  <DownloadQrCode type="lawyer" size="100" logo="xiaoheipaolvshiduan"/>
                </div>
                <span>小黑袍律师端</span>
              </div>
              <div class="code_item">
                <div class="code-container">
                  <img class="lawwit-qr" :src="xiazaicode" alt="" />
                </div>
                <span>LawWit</span>
              </div>
            </div>
          </div>
          <div
            v-if="isNav"
            class="nav_bar"
            :style="{transform: `translateX(${navOffsetX}px)`}"
          />
        </section>
        <button :class="['logo_btn']" @click="loginHandel">登录LawWit</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { debounce } from 'lodash-es';
import DownloadQrCode from '../DownloadQrCode/index.vue';
import xiazaicode from '@/assets/img/kedou/xiazaicode.jpeg'

function calcActiveNavBarOffsetX() {
  const domList = document.querySelector('.nav_list');
  const domActive = document.querySelector('.nav_item.nav_active');
  const domNavBar = document.querySelector('.nav_bar');
  if (!domList || !domActive || !domNavBar) {
    return 0;
  }

  const rcList = domList.getBoundingClientRect();
  const rcActive = domActive.getBoundingClientRect();
  const rcNavBar = domNavBar.getBoundingClientRect();

  return Math.floor(rcActive.left + (rcActive.width - rcNavBar.width) / 2) - rcList.left;
}

export default {
  data() {
    return {
      routerList: [
        // { type: 'route', name: 'user', label: '小黑袍' },
        // { type: 'route', name: 'heipao', label: '小黑袍' },
        // { type: 'route', name: 'lawyer', label: '小黑袍律师端' },
        { type: 'route', name: 'ai', label: 'Law Wit' },
        // { type: 'route', name: 'lawlion', label: '法狮众服' },
        { type: 'route', name: 'technology', label: '律舟科技' },
      ],
      navOffsetX: 0,
      xiazaicode,
    };
  },
  created() {
    this.debouncedTranslateActiveBar = debounce(() => {
      this.navOffsetX = calcActiveNavBarOffsetX();
    }, 200);
  },
  mounted() {
    const route = useRoute();
    watch(
      () => route.path,
      () => {
        this.debouncedTranslateActiveBar();
      },
      { immediate: true },
    );
  },
  beforeUnmount() {
    this.debouncedTranslateActiveBar.cancel();
  },
  computed: {
    ...mapState(['theme']),
    isNav() {
      if (this.routerList.find(item => item.name === this.$route.name)) {
        return true;
      }
      return false;
    },
  },

  methods: {
    navClick(navItem) {
      if (navItem.type === 'route' && this.$route.name !== navItem.name) {
        this.$router.push({ name: navItem.name });
      }
    },
    logoClick() {
      this.$router.push('/');
    },
    loginHandel() {
      window.open('https://www.ilawwit.com/', '_blank');
    },
  },
  components: { DownloadQrCode },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s;
  z-index: 10;
  .header_content {
    width: 100%;
    max-width: 1760px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;

    .logo {
      position: relative;
      width: 158px;
      height: 34px;
      background-image: url('./../../assets/img/kedou/heipaologob.png');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      cursor: pointer;
    }

    .nav_menu {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      min-width: 490px;
      .nav_list {
        height: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-right: 40px;
        position: relative;
        .nav_item {
          margin: 0 30px;
          font-size: 16px;
          transition: all .3s;
          position: relative;
          &:hover {
            .code_list {
              display: flex;
              opacity: 1;
            }
          }
          .code_list {
            display: none;
            opacity: 0;
            width: 152px;
            position: absolute;
            bottom: -180px;
            right: -20px;
            border-radius: 10px;
            transition: all .3s;
            backdrop-filter: blur(4px);
            filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.5));
            justify-content: space-between;
            align-items: center;
            padding: 25px;
            .arrow {
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              top: -18px;
              right: 25px;
              box-sizing: content-box;
              position: absolute;
            }
            .code_item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              color: #333333;
              font-size: 12px;
              font-weight: 900;
              color: #7b7b7b;
              line-height: 18px;
              .code-container {
                position: relative;
                width: 100px;
                height: 100px;
                margin-bottom: 10px;
                border-radius: 4px;
                border: 1px solid #e6e6e6;
                box-sizing: content-box;
                overflow: hidden;
                > * {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
        }
        .nav_bar {
          position: absolute;
          width: 20px;
          height: 3px;
          bottom: -12px;
          left: 0;
          border-radius: 1.5px;
          transform: translateX(0);
          transition: all .3s;
        }
      }
      .logo_btn {
        width: 110px;
        height: 36px;
        border-radius: 18px;
        line-height: 34px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        transition: all .3s;
        &:hover {
          background: #1C67FF;
          border: 1px solid#1C67FF;
          color: #f9f9f9;
          transform: scale(1.1);
        }
        &:active {
          transform: scale(1.2);
        }
      }
      .btn_none {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.light {
  .logo {
    width: 158px;
    height: 34px;
    background-image: url('./../../assets/img/kedou/heipaologob.png') !important;
    cursor: pointer;
  }

  &.page-lawlion {
    .logo {
      width: 32px;
      height: 32px;
      background-image: url('./../../assets/img/kedou/lawlion-logo.png') !important;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        transform: translate(40px, -50%);
        content: '法狮众服';
      }
    }
  }

  .logo_btn {
    color: #fefefe;
    border: 1px solid #fefefe;
  }
  .nav_item {
    color: rgba(255, 255, 255, 0.64);
  }
  .nav_active {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
  }
  .nav_bar {
    background: #ffffff;
  }
  .code_list {
    background: #ffffff;
    .arrow {
      border-bottom: 10px solid #ffffff;
    }
  }
}

.dark {
  .logo {
    background-image: url('./../../assets/img/kedou/heipaologo-black.png') !important;
  }

  &.page-lawlion {
    .logo {
      width: 32px;
      height: 32px;
      background-image: url('./../../assets/img/kedou/lawlion-logo.png') !important;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        color: #333;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        transform: translate(40px, -50%);
        content: '法狮众服';
      }
    }
  }

  .logo_btn {
    border: 1px solid #9b9b9b;
    color: #333333;
  }
  .nav_item {
    color: rgba(51, 51, 51, 0.64);
  }
  .nav_active {
    color: rgba(51, 51, 51, 1);
    font-weight: 400;
  }
  .nav_bar {
    background: #333333;
  }
  .code_list {
    background: #fcfcfc;
    .arrow {
      border-bottom: 10px solid #fcfcfc;
    }
  }
}
</style>
