<template>
  <div :class="`page-${$route.name}`">
    <div
      class="top"
      v-show="!isFixed && !isReason"
      :style="{ background: !isShow ? 'transparent' : '#fff' }"
    >
      <div
        :class="['logo', isSettleView || isShow ? '' : 'logo-trans']"
      />
      <button @click="select" class="more-btn">
        <img :src="more" alt="" v-if="!isShow" />
        <img :src="close2" alt="" v-if="isFixed" />
        <img :src="close" alt="" v-if="isShow" />
      </button>
      <div class="navwrap" v-show="isShow">
        <div v-for="(item, i) in navList" :key="item.value" :class="item.isActive ? 'active' : ''">
          <button @click="changeNav(i)">{{ item.value }}</button>
        </div>
      </div>
    </div>
    <div class="topf" v-show="isFixed && !isReason">
      <div class="fixed-logo" />
      <button @click="select" class="more-btn">
        <img :src="close2" alt="" v-if="!isShow" />
        <img :src="close" alt="" v-if="isShow" />
      </button>
      <div class="navwrap" v-show="isShow">
        <div v-for="(item, i) in navList" :key="item.value" :class="item.isActive ? 'active' : ''">
          <button @click="changeNav(i)">{{ item.value }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/mobile/close.png';
import close2 from '@/assets/mobile/close2.png';
import more from '@/assets/mobile/more.png';

let time = 0;

export default {
  name: 'MobileNav',
  props: {
    msg: String,
  },
  computed: {
    isSettleView() {
      return this.$route.path.startsWith('/lvzhou/settle');
    },
  },
  data() {
    return {
      more,
      close,
      isShow: false,
      isFixed: false,
      close2,
    };
  },
  setup() {
    const isReason = false;
    const newList = [
      // {
      //   isActive: true,
      //   value: '小黑袍',
      //   name: 'mkedou',
      // },
      // {
      //   isActive: false,
      //   value: '小黑袍律师端',
      //   name: 'mlawyer',
      // },
      {
        isActive: true,
        value: 'Law Wit',
        name: 'msmart',
      },
      // {
      //   isActive: false,
      //   value: '法狮众服',
      //   name: 'mlawlion',
      // },
      {
        isActive: false,
        value: '律舟科技',
        name: 'mlvzhou',
      },
    ];
    return {
      navList: newList,
      isReason,
    };
  },
  methods: {
    select() {
      this.isShow = !this.isShow;
    },
    changeNav(i) {
      for (let y = 0; y < 2; y += 1) {
        this.navList[y].isActive = false;
      }
      this.navList[i].isActive = true;
      this.isShow = false;
      this.$router.push({ name: this.navList[i].name });
    },
  },
  mounted() {
    const pName = window.location.pathname.split('/')[2] || 'msmart';
    for (let y = 0; y < 2; y += 1) {
      this.navList[y].isActive = false;
      if (pName === this.navList[y].name) {
        this.changeNav(y)
      }
    }
    const that = this;
    document.body.onscroll = function () {
      const now = new Date().getTime();
      if (now - time > 80) {
        time = now;
        if (that.isShow) {
          that.isShow = false;
        }
        const height = document.documentElement.scrollTop || document.body.scrollTop;
        if (height > 100) {
          that.isFixed = true;
        } else {
          that.isFixed = false;
        }
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.vw(@name, @px) {
  @{name}: unit((@px / 3.75), vw);
}
.top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  .vw(height, 50);

  .logo {
    display: inline-block;
    position: relative;
    float: left;
    .vw(height, 30);
    .vw(width, 92);
    .vw(margin-top, 12);
    .vw(margin-left, 26);
    background-image: url('./../../../assets/img/kedou/heipaologo-black.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    &.logo-trans {
      background-image: url('./../../../assets/img/kedou/heipaologob.png');
    }
  }

  .more-btn {
    position: relative;
    float: right;
    .vw(height, 32);
    .vw(width, 32);
    .vw(margin-right, 14);
    .vw(margin-top, 9);
    border: 0;
    background-color: transparent;
    img {
      .vw(height, 32);
      .vw(width, 32);
    }
  }
  .navwrap {
    position: absolute;
    left: 0;
    .vw(top, 50);
    width: 100%;
    .vw(padding-top, 20);
    .vw(padding-bottom, 20);
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      .vw(line-height, 50);
      text-align: center;
      button {
        .vw(line-height, 50);
        color: #333;
      }
    }
    .active {
      text-align: center;
      button {
        color:#1C67FF;
      }
    }
  }
}
.topf {
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  height: 100%;
  width: 100%;
  z-index: 99;
  .vw(height, 50);
  background: #fff;

  .fixed-logo {
    position: relative;
    float: left;
    .vw(height, 30);
    .vw(margin-top, 12);
    .vw(width, 92);
    .vw(margin-left, 26);
    background-image: url('./../../../assets/img/kedou/heipaologo-black.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }

  .more-btn {
    position: relative;
    float: right;
    .vw(height, 32);
    .vw(width, 32);
    .vw(margin-right, 14);
    .vw(margin-top, 9);
    border: 0;
    background-color: transparent;
    img {
      .vw(height, 32);
      .vw(width, 32);
    }
  }
  .navwrap {
    position: absolute;
    left: 0;
    .vw(top, 50);
    width: 100%;
    .vw(padding-top, 20);
    .vw(padding-bottom, 20);
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      .vw(line-height, 50);
      text-align: center;
      button {
        .vw(line-height, 50);
        color: #333;
      }
    }
    .active {
      text-align: center;
      button {
        color:#1C67FF;
      }
    }
  }
}

.page-mlawlion {
  .logo,
  .fixed-logo {
    .vw(width, 24);
    .vw(height, 24);
    background-image: url('../../../assets/img/kedou/lawlion-logo.png') !important;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      color: #333;
      .vw(font-size, 17);
      font-weight: bold;
      white-space: nowrap;
      transform: translate(106%, -50%);
      content: '法狮众服';
    }
  }

  .logo.logo-trans::after {
    color: #fff;
  }
}
</style>
