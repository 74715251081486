<template>
  <div class="app">
    <HeaderView v-if="isPc" />
    <div v-if="!isPc" class="nav_wrap">
      <MobileNav />
    </div>
    <router-view />
  </div>
</template>

<style lang="less" scoped>
.app {
  width: 100%;
  .nav_wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import { getDeviceBasics } from '@/utils/device';
import HeaderView from '@/components/HeaderView/HeaderView.vue';
import MobileNav from './views/mobile/component/MobileNav.vue';

export default {
  name: 'App',
  components: {
    MobileNav,
    HeaderView,
  },
  created() {
    const devInfo = getDeviceBasics();
    this.$store.commit('setOSName', devInfo.osName);
    this.$store.commit('setIsPc', !devInfo.isMobile);
  },
  computed: {
    ...mapState(['isPc']),
  },
  mounted() {
    if (this.isPc) {
      document.body.style.minWidth = '1340px';
    }
  },
};
</script>
