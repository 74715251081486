const mutations = {
  setTheme(state, payload) {
    state.theme = payload;
  },
  setIsPc(state, payload) {
    state.isPc = payload;
  },
  setOSName(state, payload) {
    state.osName = payload;
  },
};

export default mutations;
