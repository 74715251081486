<template>
  <div class="contaner">
    <qrcode-vue :value="value" :size="size" level="M" />
    <div class="logo" v-if="logo">
      <img v-if="logo === 'xiaoheipao'"
       src="../../assets/download/downloadxiaoheipao.png" alt="">
      <img v-if="logo === 'xiaoheipaolvshiduan'"
       src="../../assets/download/downloadlvshiduan.png" alt="">
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 100,
    },
    logo: {
      type: String,
    },
  },
  data() {
    return {
      value: '123',
    };
  },
  mounted() {
    const { protocol, hostname, port } = window.location;
    this.value = `${protocol}//${hostname}:${port}/download?type=${this.$props.type}`;
  },
};
</script>

<style lang="less" scoped>
.contaner {
  position: relative;
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    margin-left: -12px;
    margin-top: -12px;
    background: #d1d1d1;
    img {
      width: 25px;
    }
  }
}
</style>
