import { createApp } from 'vue';
// import VConsole from 'vconsole';
import App from './App.vue';
import router from './router';
import './assets/css/reset.css';
import 'element-plus/dist/index.css';
import store from './store/index';
import 'swiper/dist/css/swiper.min.css';

// function initVConsole() {
//   const regDevServer = /\.kedoufuwu\.com$/i;
//   const showConsole = (
//     regDevServer.test(window.location.hostname) || process.env.NODE_ENV === 'development'
//   );

//   if (showConsole && !window.vconsole) {
//     window.vconsole = new VConsole();
//   }
// }

const app = createApp(App);
app.use(store);
app.use(router);

app.mount('#app');

// initVConsole();
